import { Link } from "react-router-dom";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { signup } from "../app/userReducers/actions";
import Navbar from "../components/Navbar/Navbar";
import Personal from "./AuthSteps/Personal";
import Accounts from "./AuthSteps/Accounts";

const SignUp = () => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    middleName: "",
    currency: "",
    country: "",
    phoneNumber: "",
  });
  const [position, setPosition] = useState(0);
  const [agree, setAgree] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      user.currency === "" ||
      user.country === "" ||
      user.phoneNumber === ""
    ) {
      toast.error("Please fill in all details");
    } else if (agree === false) {
      toast.error("Please agree to the terms and conditions");
    } else {
      dispatch(signup(user));
      setUser({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        password: "",
        currency: "",
        country: "",
        phoneNumber: "",
      });
    }
  };
  const steps = [
    {
      name: "Personal Information",
      component: <Personal user={user} setUser={setUser} />,
    },
    {
      name: "Account Information",
      component: (
        <Accounts
          user={user}
          setUser={setUser}
          agree={agree}
          setAgree={setAgree}
        />
      ),
    },
  ];

  const handlePosition = (e) => {
    e.preventDefault();
    if (position === 1) {
      return;
    }
    if (
      user.lastName === "" ||
      user.email === "" ||
      user.password === "" ||
      user.firstName === ""
    ) {
      toast.error("Please fill all information");
    } else {
      setPosition(position + 1);
    }
  };
  return (
    <div>
      <Navbar />
      <div className="auth__section">
        <form className="shadow-5">
          {state.error ? (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>{state.error}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )}
          <h3>Create Account</h3>

          {steps[position].component}
          <div className="form__group">
            {position === 0 && <button onClick={handlePosition}>Next</button>}
            {position === 1 && (
              <button onClick={handleSubmit} disabled={!agree}>
                Sign Up
              </button>
            )}
          </div>
          <p className="alt">
            Have an account? <Link to="/login">Login</Link>{" "}
          </p>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SignUp;
