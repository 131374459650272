import React, { useState, useEffect } from "react";
import "../styles/Home.css";
// import { MdArrowDropDown } from "react-icons/md";
// import Buttons from "../components/Buttons";

// Components and Utils
import ThreeSteps from "../components/Home/ThreeSteps";
import Footer from "../components/Footer";
// eslint-disable-next-line
import { Link, useNavigate } from "react-router-dom";
import Lists from "../components/Home/Lists";
// import { allCountries } from "../utils/countries";
import { url } from "../utils/url";
import axios from "axios";
import MobileApp from "../components/MobileApp";
import ReactLoading from "react-loading";
import Navbar from "../components/Navbar/Navbar";

const Home = () => {
  const localCountry = localStorage.getItem("country");
  const localOperatorId = localStorage.getItem("operatorIDD");
  const [operators, setOperators] = useState([]);
  const [token, setToken] = useState("");
  const [country, setCountry] = useState("" || localCountry);
  const [showOperators, setShowOperators] = useState(false);
  const [operatorId, setOperatorId] = useState("" || localOperatorId);
  // const [active, setActive] = useState(false)
  const [activeDiv, setActiveDiv] = useState(null);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Fetch Reloadly Token
  useEffect(() => {
    axios.get(`${url}/token`).then((response) => {
      setToken(response.data.token);
    });
  }, []);

  // Make API Country Call

  useEffect(() => {
    if (token && country) {
      setLoading(true);
      axios
        .get(
          `https://topups.reloadly.com/operators/countries/${country}?includeBundles=true&includeData=true&includePin=true&suggestedAmounts=true&suggestedAmountsMap=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setOperators(response.data);
          console.log(response.data);
          setShowOperators(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [country, token]);

  // Fetch Countries
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://topups.reloadly.com/countries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCountries(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  // Get OperatorId Function
  const getOperatorId = (id) => {
    setOperatorId(id);
    setActiveDiv(id);
  };

  // Active function
  const getActiveClass = (id) => {
    if (activeDiv === id) {
      return "operator__card active";
    } else {
      return "operator__card";
    }
  };

  // Function that applies active class to card
  // const applyActive = () => {
  //   const currentClass = document.getElementsByClassName("operator__card")
  //   for (let i = 0; i < currentClass.length; i++) {
  //     currentClass[i].classList.toggle("active_item");
  //     console.log(currentClass[i]);
  //   }
  // }
  const navigateToDashboard = () => {
    localStorage.setItem("country", country);
    localStorage.setItem("operatorIDD", operatorId);
    navigate(`/dashboard/top-up/${country}/${operatorId}`);
  };
  return (
    <div>
      <Navbar />
      {/* <Navbar />
      <div className="banner">
        <div className="banner__container">
          <div className="banner__info">
            <h1>JireTopUp</h1>
            <h2>No. 1 Global Airtime App</h2>
            <div className="banner__info2">
              <p>
                Buy instant Airtime/top-up & Data Bundle online home or
                abroad/from anywhere, at the comfort of your device without
                leaving your home or office at low price.
              </p>
            </div>
          </div>
          <div className="banner__top">
            <h1>Send mobile top-up Online</h1>
            <div className="banner__input">
              <div className="banner__inputleft">
                <img src="nig.jpg" alt="country" />
                <span>+234</span>
                <MdArrowDropDown />
              </div>

              <input type="text" placeholder="Choose Country" name="country" />
              
              <div className="banner__topup">
                <form className="banner__form">
                  <select
                    name="countries"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="countries__select banner__options"
                  >
                    <option hidden>Select Country</option>
                    {countries.map((country, index) => (
                      <option value={country.isoName} key={index}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </form>
              </div>

              <div><SendIcon /></div>
            </div>
            {!showOperators ? (
              <span className="home__message">
                <h2>Enjoy</h2>
                <ul className="banner__list">
                  <li>140+ top up destinations</li>
                  <li>430+ operators worldwide</li>
                  <li>Quick order processing & Instant mobile top up</li>
                  <li>Multiple payment methods</li>
                  <li>100% secure transactions</li>
                </ul>
              </span>
            ) : !loading ? (
              <div className="operators">
                {operators.map((operator) => (
                  <div
                    className={`${getActiveClass(
                      operator.operatorId
                    )} operator__result`}
                    key={operator.id}
                    onClick={() => getOperatorId(operator.operatorId)}
                  >
                    <img src={operator.logoUrls[0]} alt="Logo Avatar" />
                    <p>{operator.name}</p>
                  </div>
                ))}
                {console.log(operatorId)}
              </div>
            ) : (
              <>
                <ReactLoading
                  type={"spin"}
                  color={"#fee3ec"}
                  height={200}
                  width={100}
                />{" "}
                <br />
              </>
            )}
            {country && (
              <button
                onClick={() => navigateToDashboard()}
                className="banner__button"
              >
                Recharge Now
              </button>
            )}
          </div>
        </div>
      </div> */}
      <div className="home__page">
        <div className="home__description">
          <h1 className="heading__topup">
            Jire<span>TopUp</span>
          </h1>
          <h1>No. 1 Global Airtime App</h1>
          <p>
            Buy instant Airtime/top-up & Data Bundle online home or abroad/from
            anywhere, at the comfort of your device without leaving your home or
            office at low price.
          </p>
        </div>
        <div className="home__image added">
          <form>
            <select
              name="countries"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="countries__select"
            >
              <option hidden>Select Country</option>
              {countries.map((country, index) => (
                <option value={country.isoName} key={index}>
                  {country.name}
                </option>
              ))}
            </select>
          </form>
          {!showOperators ? (
            <Lists />
          ) : !loading ? (
            <div className="operators">
              {operators.map((operator) => (
                <div
                  className={getActiveClass(operator.operatorId)}
                  key={operator.id}
                  onClick={() => getOperatorId(operator.operatorId)}
                >
                  <img src={operator.logoUrls[0]} alt="Logo Avatar" />
                  <p>{operator.name}</p>
                </div>
              ))}
              {console.log(operatorId)}
            </div>
          ) : (
            <>
              <ReactLoading
                type={"spin"}
                color={"#fee3ec"}
                height={200}
                width={100}
              />{" "}
              <br />
            </>
          )}
          {country && (
            <button onClick={() => navigateToDashboard()}>Recharge Now</button>
          )}
        </div>
      </div>
      {/* Three tabs section */}
      <ThreeSteps />
      {/* Easy and Faster */}
      <div className="easy__faster">
        <div className="easy__lists">
          <h3>It's Easy and Fast</h3>
          <ul>
            <li>Recharge airtime & Data from any part of the world</li>
            <li>Recharge for yourself, friends and loved ones</li>
            <li>Fast, safe, instant, and secured payment</li>
            <li>Tested and trusted by top brands</li>
            <li>Used by millions of customers</li>
          </ul>
          <Link to="/login">
            <button>Get Started</button>
          </Link>
        </div>
        <div className="easy__image">
          <img
            src="https://media.istockphoto.com/photos/young-man-using-smart-phone-at-home-picture-id1278009593?b=1&k=20&m=1278009593&s=170667a&w=0&h=uCTPNkqE--LHcrjm14dtmg_NeOjLqi3_9hQfeM9xrVg="
            alt="Avatar"
          />
        </div>
      </div>

      <MobileApp />
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Home;
