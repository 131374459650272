import "./App.css";
import { useEffect } from "react";
// import WaitList from './components/WaitList/WaitList';
// import WaitListNavbar from './components/WaitList/WaitListNavbar';
// import Navbar from "./components/Navbar/Navbar";
import Home from "./views/Home";
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import Private from "./views/Private";
import Auth from "./views/Auth";

import { useDispatch } from "react-redux";
import { /*userDetails*/ getUser } from "./app/userReducers/actions";
import { getLocation } from "./app/locationReducers/actions";
import Dashboard from "./views/Dashboard";
import Index from "./components/Dashboard/Index";
import Wallets from "./components/Dashboard/Wallets";
import TopUp from "./components/Dashboard/TopUp";
import Transactions from "./components/Dashboard/Transactions";
import Profile from "./components/Dashboard/Profile";
import StripePay from "./components/Dashboard/StripePay";
import Successful from "./components/Dashboard/Successful";
import SuccessfulTopUp from "./components/Dashboard/SuccessfulTopUp";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(userDetails());
    dispatch(getUser());
    dispatch(getLocation());
  }, [dispatch]);

  return (
    <div className="App">
      {/* <WaitListNavbar />
      <WaitList /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<Auth />}>
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
          </Route>
          <Route element={<Private />}>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="" element={<Index />} />
              <Route path="wallets" element={<Wallets />} />
              <Route path="top-up" element={<TopUp />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="profile" element={<Profile />} />
              <Route path="refill" element={<StripePay />} />
              {/* Double Params */}
              <Route path="top-up/:country/:operatorIDD" element={<TopUp />} />
              <Route path="top-up/:country" element={<TopUp />} />
              <Route path="successful/" element={<Successful/>} />
              <Route path="successful-top" element={<SuccessfulTopUp />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
