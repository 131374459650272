import React from "react";
import { useSelector } from "react-redux";
import "../../styles/Dashboard.css";

const Transactions = () => {
  const { transactions } = useSelector((state) => state.user.user);
  console.log(transactions);
  return (
    <div className="transaction__section">
      <h3>All Transactions</h3>
      {transactions && transactions.length > 0 ? (
        <>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Description</th>
                <th scope="col">Total</th>
                <th scope="col">Status</th>
                <th scope="col">Reference</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <th>{transaction.transactionDate}</th>
                  <td>{transaction.operatorName}</td>
                  <td>
                    {transaction.deliveredAmountCurrencyCode}{" "}
                    {transaction.deliveredAmount}
                  </td>
                  <td>{transaction.status}</td>
                  <td>{transaction.transactionId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <h5 style={{
          fontSize: "14px",
          marginTop: "20px"
        }}>You don't have any transactions</h5>
      )}
    </div>
  );
};

export default Transactions;
