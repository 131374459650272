import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import axios from "axios";
import { url } from "../../utils/url";

const SuccessfulTopUp = () => {
  const [loading, setLoading] = useState(false);
  const amount = sessionStorage.getItem("amount");
  const operatorId = sessionStorage.getItem("operatorId");
  const phoneNumber = sessionStorage.getItem("phoneNumber");
  const countryCode = sessionStorage.getItem("countryCode");

  const recipientDetails = {
    countryCode,
    number: phoneNumber,
  };
  const body = {
    amount,
    operatorId,
    recipientPhone: recipientDetails,
  };

  useEffect(() => {
    setLoading(true);
    if (amount) {
      const response = axios.post(`https://topups.reloadly.com/topups`, body, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("reloadlyToken")}`,
        },
      });
      if (response.statusText === "OK") {
        const transaction = response.data;
        sessionStorage.setItem("amount", "");
        sessionStorage.setItem("operatorId", "");
        sessionStorage.setItem("countryCode", "");
        sessionStorage.setItem("phoneNumber", "");
        axios
          .post(`${url}/transaction`, transaction, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
        console.log(response);
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [amount]);

  return (
    <>
      {loading ? (
        <ReactLoading type="spin" color="#044cac" width={40} height={40} />
      ) : (
        <div className="bg-body shadow p-5 text-center">
          <h3>Top Up Successful</h3>
          <button>Okay</button>
        </div>
      )}
    </>
  );
};

export default SuccessfulTopUp;
