import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../app/userReducers/actions";
import axios from "axios";
import { url } from "../../utils/url";
import Toaster, { toast } from "react-hot-toast";
import ReactLoading from "react-loading";
import { useNavigate, Link } from "react-router-dom";

const Profile = () => {
  const state = useSelector((state) => state.user);
  const [showPassword, setShowPassword] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [user, setUser] = useState({
    email: state.user.email ? state.user.email : "",
    password: "",
    currency: state.user.currency ? state.user.currency : "",
    timezone: state.user.timezone ? state.user.timezone : "",
    firstName: state.user.firstName ? state.user.firstName : "",
    lastName: state.user.lastName ? state.user.lastName : "",
    phoneNumber: state.user.phoneNumber ? state.user.phoneNumber : "",
    address: state.user.address ? state.user.address : "",
    country: state.user.country ? state.user.country : "",
  });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleEdit = () => {
    setShowEdit(true);
  };

  const handleSumbit = async () => {
    setLoading(true);
    await axios
      .put(`${url}/profile`, user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.sucess) {
          setShowEdit(false);
          setLoading(false);
          toast.success(`${response.data.message} 😅`);
          navigate("/dashboard/profile");
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.success(err.response.data.message);
      });
  };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <>
      {!showEdit ? (
        <div className="profile__section">
          <h1>My Profile</h1>
          <h3>Hello {state.user.firstName}</h3>
          <div className="profile__details">
            <h3>Account Details</h3>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Email</h5>  
                  <input type="text" defaultValue={state.user.email} disabled />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <div className="password__hide">
                    <h5>Password</h5>
                    <p onClick={() => togglePassword()}>Show Password</p>
                  </div>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={state.password}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Currency</h5>
                  <input
                    type="text"
                    defaultValue={state.user.currency}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Timezone</h5>
                  <input
                    type="text"
                    defaultValue={state.user.timezone}
                    disabled
                  />
                </div>
              </div>
            </div>

            <h3>Personal Details</h3>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>First & Middle Names</h5>
                  <input
                    type="text"
                    defaultValue={state.user.firstName}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Last Name</h5>
                  <input
                    type="text"
                    defaultValue={state.user.lastName}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Phone Number</h5>
                  <input
                    type="number"
                    defaultValue={state.user.phoneNumber}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Address</h5>
                  <input
                    type="text"
                    defaultValue={state.user.address}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Post Code</h5>
                  <input
                    type="text"
                    defaultValue=""
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Country</h5>
                  <input
                    type="text"
                    defaultValue={state.user.country}
                    disabled
                  />
                </div>
              </div>
            </div>

            <button onClick={() => toggleEdit()}>Edit Profile</button>

            <h3 className="mt-5">Credit/Debit Card</h3>
            <div className="profile__detail">
              <Link to="/dashboard/refill">
                <button>Add New Card</button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="profile__section">
          <h3>Edit Profile</h3>
          <div className="profile__details">
            <h3>Account Details</h3>
            <div className="row">
              {/* <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Email</h5>
                  <input
                    type="text"
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <div className="password__hide">
                    <h5>Password</h5>
                    <p onClick={() => togglePassword()}>Show Password</p>
                  </div>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                  />
                </div>
              </div> */}
            </div>
            <div className="row">
              {/* <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Currency</h5>
                  <input
                    type="text"
                    value={user.currency}
                    onChange={(e) =>
                      setUser({ ...user, currency: e.target.value })
                    }
                  />
                </div>
              </div> */}
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Timezone</h5>
                  <input
                    type="text"
                    value={user.timezone}
                    onChange={(e) =>
                      setUser({ ...user, timezone: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <h3>Personal Details</h3>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>First Name</h5>
                  <input
                    type="text"
                    value={user.firstName}
                    onChange={(e) =>
                      setUser({ ...user, firstName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Last Name</h5>
                  <input
                    type="text"
                    value={user.lastName}
                    onChange={(e) =>
                      setUser({ ...user, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Phone Number</h5>
                  <input
                    type="text"
                    value={user.phoneNumber}
                    onChange={(e) =>
                      setUser({ ...user, phoneNumber: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Address</h5>
                  <input
                    type="text"
                    defaultValue={user.address}
                    onChange={(e) =>
                      setUser({ ...user, address: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="profile__detail">
                  <h5>Country</h5>
                  <input
                    type="text"
                    defaultValue={user.country}
                    onChange={(e) =>
                      setUser({ ...user, country: e.target.value })
                    }
                  />
                </div>  
              </div>
            </div>

            <button onClick={handleSumbit}>
              {loading ? (
                <ReactLoading
                  type="balls"
                  color="#044cac"
                  height={40}
                  width={40}
                />
              ) : (
                "Save Details"
              )}
            </button>
          </div>
          <Toaster position="top-right" />
        </div>
      )}
    </>
  );
};

export default Profile;
