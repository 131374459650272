import React, { useState, useEffect } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
// eslint-disable-next-line
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PaymentForm = ({ clientSecret, amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  // eslint-disable-next-line
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const secret = clientSecret;
    if (!secret) {
      return;
    }

    stripe.retrievePaymentIntent(secret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        // case "requires_payment_method":
        //   setMessage("Your payment was not successful, please try again.");
        // break;
        default:
          setMessage("");
          break;
      }
    });
    // eslint-disable-next-line
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    // sessionStorage.setItem("amount", amount)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://jiretopup.com/dashboard/successful/`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured");
    }

    setLoading(false);
  };

  return (
    <div className="payment__form">
      <h1>Refill Wallet</h1>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element"></PaymentElement>
        {elements && (
          <button disabled={loading || !stripe || !elements} id="submit">
            <span id="button-text">
              {loading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay Now"
              )}
            </span>
          </button>
        )}
      </form>
      {message && <div id="payment-message">{message}</div>}
      <ToastContainer />
    </div>
  );
};

export default PaymentForm;
