import React from "react";

const Personal = ({ user, setUser }) => {
  return (
    <div>
      <p>Personal Details</p>
      <div className="form__group">
        <input
          type="text"
          name="firstName"
          placeholder="First Name"
          value={user.firstName}
          onChange={(e) => setUser({ ...user, firstName: e.target.value })}
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="firstName"
          placeholder="Middle Name(If applicable)"
          value={user.middleName}
          onChange={(e) => setUser({ ...user, middleName: e.target.value })}
        />
      </div>
      <div className="form__group">
        <input
          type="text"
          name="lastName"
          placeholder="Last Name"
          value={user.lastName}
          onChange={(e) => setUser({ ...user, lastName: e.target.value })}
        />
      </div>
      <div className="form__group">
        <input
          type="email"
          name="email"
          placeholder="Email address"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
      </div>
      <div className="form__group">
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
      </div>
    </div>
  );
};

export default Personal;
