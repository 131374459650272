import { combineReducers } from "redux";

import { userReducer } from "./userReducers/userReducer";
import { locationReducer } from "./locationReducers/index";
import { countriesReducer } from "./countryReducers/index";

export const rootReducer = combineReducers({
  user: userReducer,
  location: locationReducer,
  countries: countriesReducer,  
});
