import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReloadlyToken } from "../../app/userReducers/actions";
import axios from "axios";

const Accounts = ({ user, setUser, agree, setAgree }) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.user);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (accessToken) {
      axios
        .get(`https://topups.reloadly.com/countries`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          setCountries(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessToken]);

  useEffect(() => {
    dispatch(getReloadlyToken());
  }, [dispatch]);
  return (
    <div>
      <p>Account Details</p>
      <div className="form__group">
        <select
          name="countries"
          value={user.currency}
          onChange={(e) => setUser({ ...user, currency: e.target.value })}
          className="countries__select"
        >
          <option hidden>Select Currency</option>
          {countries.map((country, index) => (
            <option value={country.currencyCode} key={index}>
              {country.name} - {country.currencyCode}
            </option>
          ))}
        </select>
      </div>
      <div className="form__group">
        <strong>
          (FOR OTHER EU/EURO REGION) <br />
          Our Platform does not support some EU Countries yet, PLEASE CHOOSE ANY EU
          COUNTRY USING EURO FOR CURRENCY CHOICE
        </strong>
        <select
          name="countries"
          value={user.country}
          onChange={(e) => setUser({ ...user, country: e.target.value })}
          className="countries__select"
        >
          <option hidden>Select Country</option>
          {countries.map((country, index) => (
            <option value={country.name} key={index}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form__group">
        <input
          type="text"
          name="phone number"
          value={user.phoneNumber}
          onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
          placeholder="Phone Number"
        />
      </div>
      <p>
        Note! All your future payments on Jiretopup will be made in your chosen
        currency. You will not be able to change your chosen currency in the
        future.
      </p>
      <input type="checkbox" value={agree} onChange={() => setAgree(!agree)} />{" "}
      By clicking on this checkbox, I agree to Jiretopup's terms and privacy
      policies
    </div>
  );
};

export default Accounts;
