import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, userDetails } from "../app/userReducers/actions";
import "../styles/Auth.css";
import Redirect from "../utils/Redirect";
import Navbar from "../components/Navbar/Navbar";
import Toaster from "react-hot-toast";

const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(user));
    getUser();
  };
  console.log(state);

  const getUser = () => {
    dispatch(userDetails());
  };

  if (state.user._id) return <Redirect to="/dashboard/top-up" />;

  return (
    <div>
      <Navbar />
      <div className="auth__section">
        <form className="shadow-5">
          {state.error ? (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>{state.error}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )}
          <h3>Welcome Back!</h3>
          <p>Login to access your account</p>

          <div className="form__group">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          </div>
          <div className="form__group">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
          </div>
          <div className="forgot__password">
            <span>Forgot Password?</span>
          </div>
          <div className="form__group">
            <button onClick={handleSubmit}>Login</button>
          </div>
          <p className="alt">
            Don't have an account? <Link to="/sign-up">Sign Up</Link>{" "}
          </p>
        </form>
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default Login;
