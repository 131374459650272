import React, { useEffect, useState } from "react";
import "../../styles/Dashboard.css";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../app/userReducers/actions";
import { getLocation } from "../../app/locationReducers/actions";
import "react-credit-cards/es/styles-compiled.css";
import CreditCardInput from "react-credit-card-input";
import axios from "axios";
import { url } from "../../utils/url";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";

const Wallets = () => {
  const state = useSelector((state) => state.user.user);
  const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState({});
  const { user } = useSelector((state) => state.user);
  console.log(user);

  const dispatch = useDispatch();
  const [card, setCard] = useState({
    cvc: "",
    expiry: "",
    number: "",
  });
  const [loading, setLoading] = useState(false);
  // Stripe elements

  // Add Card function
  const handleAddCard = () => {
    if (card.cvc === "" || card.expiry === "" || card.number === "") {
      alert("Please fill in necessary details");
      return;
    }
    const token = localStorage.getItem("token");
    if (token) {
      setLoading(true);
      axios
        .post(`${url}/cards`, card, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          toast(response.data.message);
          dispatch(getUser());
          setCard({ cvc: "", expiry: "", number: "" });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Get Countries
  useEffect(() => {
    axios
      .get(`${url}/token`)
      .then((response) => {
        axios
          .get(`https://topups.reloadly.com/countries`, {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setCountries(response.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    dispatch(getUser());
    dispatch(getLocation());
  }, [dispatch]);

  useEffect(() => {
    if (countries) {
      const foundCountry = countries.find((country) => {
        return country.currencyCode === user.currency;
      });
      if (foundCountry) {
        console.log(foundCountry);
        setCurrency(foundCountry);
      }
    }
  }, [countries, user.currency]);

  // Set Currency
  return (
    <div className="wallet__section">
      <h1>My Wallet</h1>
      <div className="wallet__balanceCard">
        <div className="balance__card">
          <h5>
            {currency && currency.currencySymbol}
            {state.creditBalance}
          </h5>
          <p>Wallet Balance</p>
        </div>
        {/* Available cards */}
        {/* <div className="available__cards">
          <h6>Your Cards</h6>
          {state.cards && state.cards.length > 0 ? (
            <>
              {state.cards.map((card) => (
                <div className="single__card">
                  <p key={card._id}>{maskify(card.number)}</p>
                  <Link to={`/dashboard/payment/${card._id}`}>
                    <button>Pay With This Card</button>
                  </Link>
                </div>
              ))}
            </>
          ) : (
            "You don't have any card. Click on 'Add Cards' to add card "
          )}
          <br />
          <button data-mdb-toggle="modal" data-mdb-target="#exampleModal">
            Add Bank Card
          </button>
        </div> */}
      </div>
      {/* Wallet top up */}
      <div className="wallet__topup">
        <h3>Fund Wallet</h3>
        <div className="payment__icons">
          <div className="credit__card">
            {/* Card Lists */}
            <Link to="/dashboard/refill">
              <button
                className="shadow-5"
                data-mdb-toggle="modal"
                data-mdb-target="#exampleModal2"
              >
                Pay With Card
              </button>
            </Link>
          </div>
          <div className="bitcoin">
            <button
              className="shadow-5"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            >
              Fund with Bitcoin
            </button>
          </div>
          <div className="paypal">
            <button
              className="shadow-5"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal4"
            >
              Fund with Paypal
            </button>
          </div>
          <div className="transfer">
            <button
              className="shadow-5"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal3"
            >
              Bank Transfer
            </button>
          </div>
        </div>
      </div>

      {/* Add Card Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Card Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-mdb-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Add Bank Card Form */}
              <CreditCardInput
                cardNumberInputProps={{
                  value: card.number,
                  onChange: (e) => setCard({ ...card, number: e.target.value }),
                }}
                cardExpiryInputProps={{
                  value: card.expiry,
                  onChange: (e) => setCard({ ...card, expiry: e.target.value }),
                }}
                cardCVCInputProps={{
                  value: card.cvc,
                  onChange: (e) => setCard({ ...card, cvc: e.target.value }),
                }}
                fieldClassName="input"
              />
              <ToastContainer />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-mdb-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddCard}
                disabled={loading}
              >
                {loading ? <ReactLoading type="bars" /> : "Save Card"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Bitcoin Pay Modal */}
      <div
        className="modal fade"
        id="exampleModal2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Pay With Bitcoin
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>This payment method is not available at the moment!</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Bank Pay Modal */}
      <div
        className="modal fade"
        id="exampleModal3"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Pay With Bank Transfer
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>This payment option is not available at the moment!</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Pay with Paypal */}
      <div
        className="modal fade"
        id="exampleModal4"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Pay With PayPal
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h5>Important Information</h5>
              <p>
                Payment with Paypal might take upto 24 hours to reflect on your
                wallet
              </p>
              <strong>Every PayPal payment comes with a fee charged!</strong>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <a
                href="https://www.paypal.com/paypalme/jiretopup"
                rel="noreferrer"
                target="_blank"
                type="button"
                className="btn btn-success"
              >
                Proceed
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallets;
