import React from "react";

const ThreeSteps = () => {
  return (
    <div className="three__steps">
      <h3>Get Recharged in Three Steps</h3>
      <div className="steps__cards">
        <div className="step__card shadow-5">
          <i className="fas fa-globe-americas"></i>
          <h5>Select your Country</h5>
          <p>
            Select your country from the list of countries available on the
            platform.
          </p>
        </div>
        <div className="step__card shadow-5">
          <i className="fas fa-cogs"></i>
          <h5>Choose your Mobile Operator</h5>
          <p>
            Select the recharge/top-up operator available in your country and
            enter your number.
          </p>
        </div>
        <div className="step__card shadow-5">
          <i className="fas fa-shopping-cart"></i>
          <h5>Select Amount</h5>
          <p>
            Choose amount to be recharged, select payment option and get
            credited.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThreeSteps;
