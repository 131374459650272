import React from "react";
import { Link } from "react-router-dom";

import "../../styles/Dashboard.css";
import { logout } from "../../app/userReducers/actions";
import { useDispatch } from "react-redux";
import Logo from "../../images/logo.png";
import { Offcanvas } from "react-bootstrap";

const Sidebar = ({ show, handleClose }) => {
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logout());
  };
  return (
    <>
      <div className="sidebar__menu">
        <Link to="/">
          <img src={Logo} alt="Logo avatar" />
          <h1 className="top__head">Jire</h1> <h1>Topup</h1>
        </Link>
        <ul>
          <Link to="/dashboard" className="nav-link px-0 align-middle">
            <li>
              <i className="fas fa-home "></i>
              <span className="ms-1 d-none d-sm-inline">Dashboard</span>
            </li>
          </Link>
          <Link to="/dashboard/top-up">
            <li>
              <i className="fas fa-shopping-cart"></i> <span>Top Up</span>
            </li>
          </Link>

          <Link to="/dashboard/transactions">
            <li>
              <i className="fas fa-list-ul"></i> <span>Transactions</span>
            </li>
          </Link>
          <Link to="/dashboard/wallets">
            <li>
              <i className="fas fa-wallet"></i> <span>Wallets</span>
            </li>
          </Link>
          <Link to="/dashboard/profile">
            <li>
              <i className="fas fa-user-circle"></i> <span>My Profile</span>
            </li>
          </Link>
          {/* <Link to="/dashboard/settings">
          <li>
            <i className="fas fa-cog"></i> Settings
          </li>
        </Link> */}
          <a href="mailto:support@jiretopup.com">
            <li>
              <i className="fas fa-question-circle"></i>{" "}
              <span>Call Support</span>
            </li>
          </a>
          <li className="logout" onClick={() => logoutUser()}>
            <i className="fas fa-sign-out-alt"></i> <span>Logout</span>
          </li>
        </ul>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <i className="fas fa-times hide__sidebar" onClick={handleClose}></i>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sidebar__menu">
            <Link to="/">
              {/* <img src={Logo} alt="Logo avatar" /> */}
              <h1 className="top__head">Jire</h1> <h1>Topup</h1>
            </Link>
            <ul>
              <Link
                to="/dashboard"
                className="nav-link px-0 align-middle"
                onClick={handleClose}
              >
                <li>
                  <i className="fas fa-home "></i>
                  <span className="">Dashboard</span>
                </li>
              </Link>
              <Link to="/dashboard/top-up" onClick={handleClose}>
                <li>
                  <i className="fas fa-shopping-cart"></i> <span>Top Up</span>
                </li>
              </Link>

              <Link to="/dashboard/transactions" onClick={handleClose}>
                <li>
                  <i className="fas fa-list-ul"></i> <span>Transactions</span>
                </li>
              </Link>
              <Link to="/dashboard/wallets" onClick={handleClose}>
                <li>
                  <i className="fas fa-wallet"></i> <span>Wallets</span>
                </li>
              </Link>
              <Link to="/dashboard/profile" onClick={handleClose}>
                <li>
                  <i className="fas fa-user-circle"></i> <span>My Profile</span>
                </li>
              </Link>
              {/* <Link to="/dashboard/settings">
          <li>
            <i className="fas fa-cog"></i> Settings
          </li>
        </Link> */}
              <a href="mailto:support@jiretopup.com">
                <li>
                  <i className="fas fa-question-circle"></i>{" "}
                  <span>Call Support</span>
                </li>
              </a>
              <li className="logout" onClick={() => logoutUser()}>
                <i className="fas fa-sign-out-alt"></i> <span>Logout</span>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
