import React from "react";

import "../styles/Home.css";
import Google from "../images/google2.png";

const MobileApp = () => {
  return (
    <div>
      {/* Mobile Apps */}
      <div className="mobile__apps">
        <h3>Make life Easier with our Mobile Apps</h3>
        <div className="mobile__actions">
          <button data-bs-toggle="modal" data-bs-target="#exampleModal">
            <div>
              <i className="fab fa-apple"></i>{" "}
            </div>
            <div className="button__details">
              <p>Download on</p>
              <h5>App Store</h5>
            </div>
          </button>
          <button data-bs-toggle="modal" data-bs-target="#exampleModal">
            <div>
              <img src={Google} alt="Google avatar" width={40} />
            </div>
            <div className="button__details">
              <p>Download on</p>
              <h5>Google Play</h5>
            </div>
          </button>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">Jire Topup mobile app coming soon</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileApp;
