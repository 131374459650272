import React, { useState } from "react";
import Logo from "../../images/logo.png";
import { AiOutlineLogout } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

import "../WaitList/WaitList.css";
import { Link } from "react-router-dom";
import { logout } from "../../app/userReducers/actions";

// UseSelector
import { useSelector, useDispatch } from "react-redux";

const Navbar = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const [showToggle, setShowToggle] = useState(false);
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logout());
  };

  const handleToggle = () => {
    setShowToggle(!showToggle);
  };

  return (
    <div className="header__section">
      <nav className="navbar">
        <div className="navbar__left">
          <Link to="/">
            <div className="navbar__logo">
              <img src={Logo} alt="logo" />
              <div className="navbar__logotitle">
                <h2>Jire</h2>
                <span className="logo__twitch">TopUp</span>
              </div>
            </div>
          </Link>
          <div className="navbar__nav">
            <h2 className="navbar__service">
              <Link to="/">Home</Link>
            </h2>
            <h2 className="navbar__support">
              <a href="mailto:support@betheldigitech.com">Support</a>
            </h2>
          </div>
        </div>
        <div className="navbar__right">
          {isLoggedIn ? (
            <>
              {" "}
              <span className="navbar__dash">
                <Link to="dashboard">Dashboard</Link>
              </span>
              <button className="navbar__logout" onClick={logoutUser}>
                <span className="navbar__logoutbtn">Logout</span>
                <AiOutlineLogout />
              </button>
            </>
          ) : (
            <>
              {" "}
              <span className="navbar__dash">
                <Link to="/login">Login</Link>
              </span>
              <button className="navbar__logout">
                <span className="navbar__logoutbtn">
                  <Link to="/sign-up">SignUp</Link>
                </span>
              </button>
            </>
          )}
        </div>
        <div onClick={handleToggle} className="navbar__toggle">
          {showToggle ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      {showToggle && (
        <div className="mobile__container">
          <nav className="mobile">
            <div className="mobile__nav">
              <h2 className="mobile__service">
                <Link to="/">Home</Link>
              </h2>
              <h2 className="mobile__support">
                <a href="mailto:support@betheldigitech.com">Support</a>
              </h2>
            </div>
            <div className="mobile__right">
              {isLoggedIn ? (
                <div className="mobile__auth">
                  {" "}
                  <span className="navbar__dash">
                    <Link to="dashboard">Dashboard</Link>
                  </span>
                  <button className="navbar__logout" onClick={logoutUser}>
                    <span className="navbar__logoutbtn">Logout</span>
                    <AiOutlineLogout />
                  </button>
                </div>
              ) : (
                <div className="mobile__auth">
                  {" "}
                  <span className="mobile__dash">
                    <Link to="/login">Login</Link>
                  </span>
                  <button className="navbar__logout">
                    <span className="navbar__logoutbtn">
                      <Link to="/sign-up">SignUp</Link>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Navbar;
