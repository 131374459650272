import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { PUBLIC_KEY, url } from "../utils/url";
// import PaymentForm from "../components/PaymentForm";
import PayWithCard from "../components/PayWithCard";
import axios from "axios";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";

const stripePromise = loadStripe(PUBLIC_KEY);

const Recharge = ({
  amount,
  topup,
  currency,
  token,
  code,
  operatorId,
  phoneNumber,
  countryCode
}) => {
  phoneNumber && console.log(phoneNumber);
  countryCode && console.log(countryCode)
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  currency && console.log(currency);
  const generateIntent = async () => {
    setLoading(true);
    await axios
      .post(
        `${url}/refill`,
        {
          amount: Math.floor(amount),
          currency: currency,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setClientSecret(response.data.clientSecret);
        sessionStorage.setItem("amount", amount)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });
  };
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <div>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <PayWithCard
            clientSecret={clientSecret}
            operatorId={operatorId}
            phoneNumber={phoneNumber}
            countryCode={countryCode}
          />
        </Elements>
      ) : (
        <button onClick={generateIntent} id="pay__with__card__button">
          {loading ? (
            <ReactLoading type="spin" color="#fff" width={30} height={30} />
          ) : (
            ` Pay ${code}
          ${amount}`
          )}
        </button>
      )}
      <ToastContainer />
    </div>
  );
};

export default Recharge;
