import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../app/userReducers/actions";
import { getLocation } from "../../app/locationReducers/actions";
import axios from "axios";
import { url } from "../../utils/url";

const Index = () => {
  const { transactions, creditBalance } = useSelector(
    (state) => state.user.user
  );
  const { user } = useSelector((state) => state.user);
  const [countries, setCountries] = useState([])
  const [currency, setCurrency] = useState({})

  const dispatch = useDispatch();

  

  // Fetch countries
  useEffect(() => {
    axios
      .get(`${url}/token`)
      .then((response) => {
        axios
          .get(`https://topups.reloadly.com/countries`, {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setCountries(response.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [])
  // Get User, Get Location UseEffect => Straight from Redux
  useEffect(() => {
    dispatch(getUser());
    dispatch(getLocation());
  }, [dispatch]);

  useEffect(() => {
    if (countries) {
      const foundCountry = countries.find((country) => {
        return country.currencyCode === user.currency;
      });
      if (foundCountry) {
        console.log(foundCountry);
        setCurrency(foundCountry)
      }
    }
  }, [countries, user.currency]);


  return (
    <div className="dashboard__index">
      <h1>My Dashboard</h1>
      {/* Dashboard Cards */}
      <div className="dashboard__cards">
        {/* Transactions */}
        <div className="transactions transaction__card shadow-5">
          <h3>{transactions && transactions.length}</h3>
          <p>Transactions</p>
          <Link to="/dashboard/transactions">
            <button>View Transactions</button>
          </Link>
        </div>

        {/* Balance */}
        <div className="balance transaction__card shadow-5">
          <h3>{currency && currency.currencySymbol}{"0.00"}</h3>

          <p>Total Spendings</p>
          <Link to="/dashboard/transactions">
            <button>View All</button>
          </Link>
        </div>

        {/* Wallet */}
        <div className="wallet transaction__card shadow-5">
          <h3>{currency && currency.currencySymbol}{creditBalance}</h3>

          <p>Wallet Balance</p>
          <Link to="/dashboard/wallets">
            <button>View Wallet</button>
          </Link>
        </div>
      </div>

      {/* Recent transactions */}
      <div className="recent__transactions shadow-5">
        <h3>Recent Transactions</h3>

        {/* Table */}
        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Description</th>
              <th scope="col">Total</th>
              <th scope="col">Status</th>
              <th scope="col">Reference</th>
            </tr>
          </thead>
          <tbody>
            {transactions && transactions.length > 0 ? (
              transactions.slice(0, 5).map((transaction, index) => (
                <tr key={index}>
                  <th>{transaction.transactionDate}</th>
                  <td>{transaction.operatorName}</td>
                  <td>
                    {transaction.deliveredAmountCurrencyCode}{" "}
                    {transaction.deliveredAmount}
                  </td>
                  <td>{transaction.status}</td>
                  <td>{transaction.transactionId}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>You have no transactions yet!</td>
              </tr>
            )}
          </tbody>
        </table>
        <Link to="/dashboard/transactions">
          <button>View Transactions</button>
        </Link>
      </div>
    </div>
  );
};

export default Index;
