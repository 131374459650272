import React from "react";
import "../../styles/Home.css"

const Lists = () => {
  return (
    <div className="lists">
      <h3>Enjoy</h3>
      <ul>
        <li>140+ top up destinations</li>
        <li>430+ operators worldwide</li>
        <li>Quick order processing & Instant mobile top up</li>
        <li>Multiple payment methods</li>
        <li>100% secure transactions</li>
        {/* <li>No hidden fees</li>
        <li>Quick order processing</li>
        <li>Instant mobile top up</li> */}
      </ul>
    </div>
  );
};

export default Lists;
