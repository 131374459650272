import React from "react";

import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__section">
        <div className="section">
          <h5>Company</h5>
          <div className="links">
            <p>Home</p>
            <p>About Us</p>
            <a href="mailto:support@jiretopup.com" _target="blank">
              <p>Contact Us</p>
            </a>
            <p>Privacy Policy</p>
            <p>Terms and Conditions</p>
          </div>
        </div>
        <div className="section">
          <h5>Pay With</h5>
          <div className="links">
            {/* <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/PayPal_logo.svg/1200px-PayPal_logo.svg.png"
              alt="Paypal Avatar"
              width={80}
              className="me-4"
            /> */}

            <img
              src="https://w7.pngwing.com/pngs/305/373/png-transparent-logo-mastercard-font-solar-home-text-orange-logo.png"
              alt="Mastercard Avatar"
              width={70}
              className="me-4"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
              alt="Visa Avatar"
              width={70}
            />
          </div>
        </div>
        <div className="section">
          <h5>Support</h5>
          <div className="links">
            <a href="mailto:support@jiretopup.com" _target="blank">
              <p>support@jiretopup.com</p>
            </a>

            <a href="https://www.facebook.com/jiretopup" _target="blank">
              <i className="fab fa-facebook-square"></i>
            </a>
            {/* <i className="fab fa-twitter-square"></i> */}
            <a href="https://www.instagram.com/jiretopup" _target="blank">
              <i className="fab fa-instagram-square"></i>
            </a>
            {/* <i className="fab fa-linkedin"></i> */}
          </div>
        </div>
      </div>

      {/* Copyright  */}
      <div className="copyright">
        <p>
          Copyright &copy;{" "}
          <a href="https://www.betheldigitech.com" _target="blank">
            betheldigitech.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
