import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../utils/url";

const Successful = () => {
  const navigate = useNavigate()
  const amount = sessionStorage.getItem("amount")
  useEffect(() => {
    if (amount) {
      axios
        .post(
          `${url}/update-amount`,
          { amount },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          sessionStorage.setItem("amount", "")
          navigate("/dashboard")
        })
        .catch((err) => {
          console.log("An error occured");
        });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className="bg-body shadow p-5 text-center">
      <h3>Payment Successful</h3>
      <Link to="/dashboard"><button>Okay</button></Link>
    </div>
  );
};

export default Successful;
