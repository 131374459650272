import axios from "axios";
import { url } from "../../utils/url";
import { toast } from "react-hot-toast";
export const login = (user) => {
  return (dispatch) => {
    axios
      .post(`${url}/login`, user)
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("password", response.data.password);
          dispatch({
            type: "LOGIN_USER",
            payload: response.data,
          });
        } else {
          console.log(response.data.message);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        dispatch({
          type: "LOGIN_ERROR",
          payload: err.response.data.message,
        });
        console.log(err.response.data.message);
        toast.error(err.response.data.message);
      });
  };
};

export const signup = (user) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LOADING",
    });
    axios
      .post(`${url}/signup`, user)
      .then((response) => {
        if (!response.data.success) {
          console.log(response);
        } else {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("password", response.data.password);

          dispatch({
            type: "SIGN_UP",
            payload: response.data,
          });
          dispatch({
            type: "RETURN_LOADING",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SIGNUP_ERROR",
          payload: err.response.data.message,
        });
        console.log(err.message);
      });
  };
};

export const userDetails = () => {
  return (dispatch, getState) => {
    const token = getState().user.token;
    if (token) {
      dispatch({
        type: "LOAD_USER",
        payload: token,
      });
    } else {
      return null;
    }
  };
};

export const getUser = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(`${url}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch({
            type: "GET_USER",
            payload: response.data,
          });
        });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT_USER",
    });
  };
};

export const getReloadlyToken = () => {
  return (dispatch) => {
    axios.get(`${url}/token`).then((response) => {
      // console.log(response.data)
      dispatch({
        type: "RELOADLY_TOKEN",
        payload: response.data,
      });
    });
  };
};
