import jwtDecode from "jwt-decode";
import { toast } from "react-hot-toast";

const initialState = {
  user: {},
  token: localStorage.getItem("token"),
  isLoggedIn: localStorage.getItem("token") ? true : false,
  password: localStorage.getItem("password") || "",
  error: "",
  accessToken: localStorage.getItem("accessToken") || "",
  loading: false
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGN_UP":
      console.log(action.payload);
      const user = jwtDecode(action.payload.token);
      console.log(user);
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLoggedIn: true,
        password: action.payload.password,
      };
    case "GET_USER":
      console.log(action.payload);
      return {
        ...state,
        user: action.payload.user,
      };
    case "LOGIN_USER":
      console.log(action.payload);
      // const loginUser = jwtDecode(action.payload.token);
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLoggedIn: true,
        password: action.payload.password,
      };

    case "LOGIN_ERROR":
      toast.error("Invalid username or password 😞");
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        user: {},
        token: localStorage.setItem("token", ""),
        isLoggedIn: false,
        password: localStorage.setItem("password", ""),
      };
    case "RELOADLY_TOKEN":
      return {
        ...state,
        accessToken: action.payload.token,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: true
      }
    case "RETURN_LOADING":
      return {
        ...state,
        loading: false
      }

    default:
      return state;
  }
};
