import React, {useState} from "react";
import { Outlet } from "react-router-dom";

import "../styles/Dashboard.css";

// Components
import Sidebar from "../components/Dashboard/Sidebar";

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="">
      {/* <div className="sidebar">
        <Sidebar />
      </div>
      <div className="outlet">
        <Outlet />
      </div> */}
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 sidebar__section">
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
              <Sidebar show={show} handleClose={handleClose} />
            </div>
          </div>
          <div className="col py-3">
            <i className="fas fa-bars show__sidebar" onClick={handleShow}></i>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
