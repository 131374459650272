import React, { useState, useEffect } from "react";
import { url } from "../../utils/url";
import axios from "axios";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { allCountries } from "../../utils/countries";
import { getUser } from "../../app/userReducers/actions";
import { getLocation } from "../../app/locationReducers/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
// eslint-disable-next-line
import { ToastContainer, toast } from "react-toastify";
import Recharge from "../../views/Recharge";

import "../../styles/Dashboard.css";
// import getCountry from "currency-map-country/lib/getCountry";

// Api URL
// `https://topups-sandbox.reloadly.com/operators/auto-detect/phone/23408146771436/countries/NG?suggestedAmountsMap=true&SuggestedAmounts=true`;

const localHeaders = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

const TopUp = () => {
  const { country, operatorIDD } = useParams();
  const localCountry = localStorage.getItem("country");
  const localOperator = localStorage.getItem("operatorIDD");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [recipientCountryCode, setRecipientCountryCode] = useState(
    country || localCountry
  );
  // eslint-disable-next-line
  const [details, setDetails] = useState({});
  // eslint-disable-next-line
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState(false);
  const [operatorId, setOperatorId] = useState(operatorIDD || localOperator);
  const [transaction, setTransaction] = useState({});
  const [showTransaction, setShowTransaction] = useState(false);
  const [token, setToken] = useState("");

  // Get Countries
  const [countries, setCountries] = useState([]);
  const [dialCode, setDialCode] = useState("");
  const [operators, setOperators] = useState([]);
  // eslint-disable-next-line
  const [showOperators, setShowOperators] = useState(false);
  const [activeDiv, setActiveDiv] = useState(null);
  const [operator, setOperator] = useState({});
  const [showOperator, setShowOperator] = useState(false);
  const [activeAmount, setActiveAmount] = useState(null);
  const [currency, setCurrency] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  // eslint-disable-next-line
  // const { user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getLocation());
    dispatch(getUser());
  }, [dispatch]);

  // Generate token function
  const generateToken = async () => {
    await axios
      .get(`${url}/token`, {
        headers: localHeaders,
      })
      .then((response) => {
        setToken(response.data.token);
        sessionStorage.setItem("reloadlyToken", response.data.token)
      })
      .catch((err) => console.log(err));
  };

  const handleNumberChange = (e) => {
    setRecipientPhone(e.target.value);
  };
  const handleCountryChange = (e) => {
    setRecipientCountryCode(e.target.value);
  };

  // const getOperator = async (e) => {
  //   setLoading(true);
  //   e.preventDefault();
  //   const response = await axios.get(
  //     `https://topups-sandbox.reloadly.com/operators/auto-detect/phone/${recipientPhone}/countries/${recipientCountryCode}?suggestedAmountsMap=true&SuggestedAmounts=true`,
  //     {
  //       headers: headers,
  //     }
  //   );

  //   if (response.statusText === "OK") {
  //     setDetails(response.data);
  //     setShowDetails(true);
  //     setLoading(false);
  //     setAmount(response.data.minAmount);
  //     setActions(true);
  //     setOperatorId(response.data.operatorId);
  //   }
  // };

  // const clearDetails = (e) => {
  //   e.preventDefault();
  //   setDetails({});
  //   setShowDetails(false);
  //   setLoading(false);
  //   setAmount(0);
  // };

  const showPaymentOptions = (e) => {
    e.preventDefault();
    if (amount === 0) {
      toast.error("Please specify an amount");
    } else if (recipientPhone === "") {
      toast.error("Please put in phone number");
    } else {
      setPaymentOptions(true);
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const topup = async () => {
    let recipientDetails = {
      countryCode: recipientCountryCode,
      number: dialCode + recipientPhone,
    };
    let body = {
      amount,
      operatorId,
      recipientPhone: recipientDetails,
    };
    if (amount > user.creditBalance || user.creditBalance - amount < 0) {
      toast.error(
        "Insufficient balance for this transaction! Kindly refill Wallet!"
      );
      return;
    }
    setLoading(true);
    const response = await axios.post(
      `https://topups.reloadly.com/topups`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.statusText === "OK") {
      const transaction = response.data;
      await axios
        .post(`${url}/transaction`, transaction, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(response);
      setTransaction(response.data);
      setShowTransaction(true);
      setLoading(false);
    }
  };

  const topupCard = async () => {
    let recipientDetails = {
      countryCode: recipientCountryCode,
      number: dialCode + recipientPhone,
    };
    let body = {
      amount,
      operatorId,
      recipientPhone: recipientDetails,
    };
    setLoading(true);
    const response = await axios.post(
      `https://topups.reloadly.com/topups`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.statusText === "OK") {
      const transaction = response.data;
      await axios
        .post(`${url}/transaction-card`, transaction, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(response);
      setTransaction(response.data);
      setShowTransaction(true);
      setLoading(false);
    }
  };

  const setReturn = () => {
    setShowTransaction(false);
    setDetails({});
    setShowDetails(false);
    setLoading(false);
    setAmount(0);
    setPaymentOptions(false);
    dispatch(getUser());
    navigate("/dashboard");
  };

  useEffect(() => {
    generateToken();
  }, []);

  // Fetch countries
  useEffect(() => {
    axios
      .get(`https://topups.reloadly.com/countries`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCountries(response.data);
        console.log(response.data);
      });
    //eslint-disable-next-line
  }, []);

  // Get Operators
  useEffect(() => {
    if (recipientCountryCode) {
      setLoading(true);
      axios
        .get(
          `https://topups.reloadly.com/operators/countries/${recipientCountryCode}?includeBundles=true&includeData=true&includePin=true&suggestedAmounts=true&suggestedAmountsMap=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          setOperators(response.data);
          setShowOperators(true);
          setLoading(false);
          setShowOperator(false);
        });
    }
  }, [recipientCountryCode, token]);

  // Get Recipient Country Code
  useEffect(() => {
    // eslint-disable-next-line
    if (countries && country) {
      const foundCountry = countries.find((country) => {
        return country.isoName === country;
      });
      if (foundCountry) {
        setDialCode(foundCountry.callingCodes[0]);
        console.log(foundCountry.callingCodes[0]);
      }
    }
    if (countries) {
      const foundCountry = countries.find((country) => {
        return country.isoName === recipientCountryCode;
      });
      if (foundCountry) {
        setDialCode(foundCountry.callingCodes[0]);
        console.log(foundCountry.callingCodes[0]);
      }
    }
    if (countries) {
      const foundCountry = countries.find((country) => {
        return country.currencyCode === user.currency;
      });
      if (foundCountry) {
        console.log(foundCountry);
        setCurrency(foundCountry);
      }
    }

    //eslint-disable-next-line
  }, [countries, country, recipientCountryCode]);

  // Get OperatorId Function
  const getOperatorId = (id) => {
    setOperatorId(id);
    setActiveDiv(id);
  };

  // Get Amount
  const getAmount = (amount) => {
    setAmount(amount);
    setActiveAmount(amount);
    console.log(amount);
  };

  // Active function
  const getActiveClass = (id) => {
    if (activeDiv === id) {
      return "operator__class active";
    } else {
      return "operator__class";
    }
  };

  const getActiveAmount = (amount) => {
    if (activeAmount === amount) {
      return "operator__card  shadow-5 active";
    } else {
      return "operator__card  shadow-5";
    }
  };

  // Get Operator UseEffect
  useEffect(() => {
    if (operatorId) {
      setLoading(true);
      axios
        .get(
          `https://topups.reloadly.com/operators/${operatorId}?suggestedAmounts=true&suggestedAmountsMap=true`,
          {
            headers,
          }
        )
        .then((response) => {
          setLoading(false);
          setOperator(response.data);
          setShowOperator(true);
          setShowOperators(false);
        });
    }
    // eslint-disable-next-line
  }, [operatorId]);

  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/com.reloadly.topups-v1+json",
  };
  console.log(currency);
  return (
    <div className="topup__section">
      <h1>Mobile Top Up</h1>
      <p>Get a top up in few minutes</p>

      <form className="">
        <div className="form__group">
          <h5>1. Select Country</h5>
          <select
            name="country"
            value={recipientCountryCode}
            onChange={handleCountryChange}
            className="countries__select"
          >
            <option hidden>Select Country</option>
            {countries.map((country) => (
              <option value={country.isoName} key={country.isoName}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        {/* Show Operators */}
        {showOperators ? (
          loading ? (
            <>
              <ReactLoading
                type={"spin"}
                color={"#044cac"}
                height={200}
                width={100}
              />{" "}
              <br />
            </>
          ) : (
            <div className="operators">
              {operators.map((operator) => (
                <div
                  className={getActiveClass(operator.operatorId)}
                  key={operator.id}
                  onClick={() => getOperatorId(operator.operatorId)}
                >
                  <img src={operator.logoUrls[0]} alt="Logo Avatar" />
                  <p>{operator.name}</p>
                </div>
              ))}
              {/* {console.log(operatorId)} */}
            </div>
          )
        ) : (
          ""
        )}
        {/* Show Operator Details */}
        <div className="operator__details">
          {showOperator ? (
            !loading ? (
              <>
                <div className="network__details">
                  {operator ? (
                    <img src={operator.logoUrls[0]} alt="Operator Avatar" />
                  ) : (
                    ""
                  )}
                  <h3>{operator.name}</h3>
                </div>
                <div className="amount__options">
                  {operator.suggestedAmountsMap
                    ? Object.keys(operator.suggestedAmountsMap).map(
                        (key, index) => (
                          <div
                            key={index}
                            className={getActiveAmount(key)}
                            onClick={() => getAmount(key)}
                          >
                            <p className="operator__head">
                              {operator.destinationCurrencySymbol}
                              {operator.suggestedAmountsMap[key]}
                            </p>
                            <p>
                              {operator.senderCurrencySymbol}
                              {key}
                            </p>
                          </div>
                        )
                      )
                    : ""}
                  <br />
                </div>
              </>
            ) : (
              <>
                <ReactLoading
                  type={"spin"}
                  color={"#044cac"}
                  height={200}
                  width={100}
                />{" "}
                <br />
              </>
            )
          ) : (
            ""
          )}
          {/* <div style={{ marginTop: "20px" }} className="either__option">
            <p>Or</p>
            <input
              type="number"
              name="amount"
              placeholder="Or Specify An Amount"
              value={amount}
              onChange={e => setAmount(e.target.value)}
            />
          </div> */}
        </div>

        <div className="form__group">
          <h5>2. Set up phone number</h5>
          <div className="input-group flex-nowrap">
            <select
              className="input-group-text"
              id="addon-wrapping"
              value={dialCode}
              onChange={(e) => setDialCode(e.target.value)}
              disabled
            >
              <option hidden>Country Code</option>
              {countries.map((country, index) => (
                <option value={country.callingCodes[0]} key={index}>
                  {country.callingCodes[0]}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
              aria-describedby="addon-wrapping"
              value={recipientPhone}
              onChange={handleNumberChange}
            />
          </div>
        </div>
        {/* {!showDetails && (
          <div className="form__group next__buttonArea">
            <button className="next__button" onClick={getOperator}>
              {!loading ? "Next" : "Loading..."}
            </button>
          </div>
        )}
        <div className="form__group">
          <h5>3. Select Recharge Amount</h5>
          {showDetails && (
            <>
              <span className="topup__range">
                (Minimum: {details.destinationCurrencyCode}
                {details.minAmount} - Maximum {details.destinationCurrencyCode}
                {details.maxAmount})
              </span>
              <input
                type="number"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <span className="topup__error">
                {amount < details.minAmount
                  ? "Amount must be within the range"
                  : amount > details.maxAmount
                  ? "Amount must be within the range"
                  : ""}
              </span>
            </>
          )}
        </div> */}

        {showOperator && (
          <div className="form__group action__buttons">
            <button className="next__button" onClick={showPaymentOptions}>
              Next
            </button>
            {/* <Toaster position="top-center" /> */}
          </div>
        )}

        <div className="form__group">
          <h5>4. Make payment</h5>
          {paymentOptions && (
            <div className="payment__options">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={preventDefault}
              >
                Pay with Wallet {operator.destinationCurrencySymbol}
                {operator.suggestedAmountsMap[amount]}{" "}
              </button>

              <button
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                onClick={preventDefault}
              >
                Pay with Card{" "}
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
                  alt="Stripe Avatar"
                  width={40}
                />
              </button>

              <div
                className="modal fade"
                id="exampleModal"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* Transaction Details */}
                    {!showTransaction ? (
                      <>
                        <div className="modal-header">
                          <h3 className="modal-title" id="exampleModalLabel">
                            Transaction Details
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            data-mdb-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <input
                            type="text"
                            disabled
                            name="operator"
                            placeholder={`Mobile Operator Name: ${operator.name} `}
                          />
                          <p>Country Code: {recipientCountryCode}</p>
                          <input
                            type="text"
                            name="amount"
                            placeholder={` ${operator.destinationCurrencySymbol}${operator.suggestedAmountsMap[amount]}`}
                            disabled
                          />
                          <p>
                            Phone Number: {dialCode}
                            {recipientPhone}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Show Transaction Success */}
                        <div className="modal-header">
                          <h3 className="modal-title" id="exampleModalLabel">
                            {transaction.status}
                          </h3>
                          <button
                            type="button"
                            className="btn-close"
                            data-mdb-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <input
                            type="text"
                            disabled
                            name="operator"
                            placeholder={`Recipient Phone: ${transaction.recipientPhone} `}
                          />
                          <p>Country Code: {recipientCountryCode}</p>
                          <input
                            type="text"
                            name="amount"
                            placeholder={` ${transaction.deliveredAmountCurrencyCode}${transaction.deliveredAmount}`}
                            disabled
                          />
                          <p>Transaction Date: {transaction.transactionDate}</p>
                          <p>Transaction ID: {transaction.transactionId}</p>
                        </div>
                      </>
                    )}

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary go__back"
                        data-bs-dismiss="modal"
                        onClick={setReturn}
                      >
                        {!showTransaction ? "Go Back" : "Close"}
                      </button>
                      {!showTransaction && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={topup}
                        >
                          {!loading ? "Complete" : "Sending 😍"}
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <Toaster position="top-center" reverseOrder={false} /> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </form>

      {/* Modals */}
      {/* Wallet Payments Modal */}
      {/* Paywith Stripe Modal */}

      <div
        className="modal fade"
        id="exampleModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      ></div>
      {/* Paywith Stripe Modal */}
      <div
        className="modal fade"
        id="exampleModal2"
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel2">
                Pay With Card
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                
              ></button>
            </div>
            <div className="modal-body">
              <Recharge
                amount={amount > 0 && operator.suggestedAmountsMap[amount]}
                topup={topupCard}
                currency={operator.destinationCurrencyCode}
                token={token}
                code={operator.destinationCurrencySymbol}
                operatorId={operatorId}
                phoneNumber={dialCode + recipientPhone}
                countryCode={recipientCountryCode}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Paypal Modal */}
      <div
        className="modal fade"
        id="paypalmodal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Pay With Card
              </h5>
              <button
                type="button"
                className="btn-close"
                data-mdb-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Recharge amount={amount} />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-mdb-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default TopUp;
