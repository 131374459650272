// export const url = "https://mighty-depths-32762.herokuapp.com/api";
export const url = "https://jiretopup-server.herokuapp.com/api";

export const localURL = "http://localhost:3044/api";
export const token =
  "eyJraWQiOiI1N2JjZjNhNy01YmYwLTQ1M2QtODQ0Mi03ODhlMTA4OWI3MDIiLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMTA1NyIsImlzcyI6Imh0dHBzOi8vcmVsb2FkbHktc2FuZGJveC5hdXRoMC5jb20vIiwiaHR0cHM6Ly9yZWxvYWRseS5jb20vc2FuZGJveCI6dHJ1ZSwiaHR0cHM6Ly9yZWxvYWRseS5jb20vcHJlcGFpZFVzZXJJZCI6IjExMDU3IiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIiwiYXVkIjoiaHR0cHM6Ly90b3B1cHMtaHMyNTYtc2FuZGJveC5yZWxvYWRseS5jb20iLCJuYmYiOjE2NDAwODQwNDUsImF6cCI6IjExMDU3Iiwic2NvcGUiOiJzZW5kLXRvcHVwcyByZWFkLW9wZXJhdG9ycyByZWFkLXByb21vdGlvbnMgcmVhZC10b3B1cHMtaGlzdG9yeSByZWFkLXByZXBhaWQtYmFsYW5jZSByZWFkLXByZXBhaWQtY29tbWlzc2lvbnMiLCJleHAiOjE2NDAxNzA0NDUsImh0dHBzOi8vcmVsb2FkbHkuY29tL2p0aSI6ImJmNDU5OTk4LTA4NzctNDIzYi1hYzEyLTVjMzZjZTc2NjNiZCIsImlhdCI6MTY0MDA4NDA0NSwianRpIjoiYmQ4ZjFiYjItZTVlMy00YTVhLThmMTEtYTVmZWIzZTY1YWFjIn0.AEE8bAAhKCOFGUbUoSCyXv1-8HGjN-scLeXeNJsLjh0";
export const countriesAPI = "https://topups-sandbox.reloadly.com/countries";

export const PUBLIC_KEY =
  "pk_live_51KA1MJHS52jZDXV1U3C7om2rUB2mPj57OgE2T1g2zI98AQ9gtvs5X4qphHlSQQ7ezmOsX5Hm358bZezaHUKoRsGb005qSFABiD";

// pk_live_51KA1MJHS52jZDXV1U3C7om2rUB2mPj57OgE2T1g2zI98AQ9gtvs5X4qphHlSQQ7ezmOsX5Hm358bZezaHUKoRsGb005qSFABiD

// pk_test_51KAZsMAYOPAYsdY7j67mP3vre7Y5yhEnUIbLpsTtFzvR0xXQoEOrdFjMQNjWOSgaNCIh6yOt0Yuoej9k8MwXjdK300orXlPzcL
