import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { PUBLIC_KEY } from "../../utils/url";
import PaymentForm from "../PaymentForm";
import axios from "axios";
import { url } from "../../utils/url";
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../../app/locationReducers/actions";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";

const stripePromise = loadStripe(PUBLIC_KEY);

const StripePay = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState({});

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const generateIntent = async () => {
    setLoading(true);
    await axios
      .post(
        `${url}/refill`,
        {
          amount: amount,
          currency: currency.currencyCode,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      )
      .then((response) => {
        console.log(response.data);
        sessionStorage.setItem("amount", amount)
        setClientSecret(response.data.clientSecret);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setLoading(false);
        toast.error(err.response.data.message || "An error occured. Kindly try again");
      });
  };

  useEffect(() => {
    axios
      .get(`${url}/token`)
      .then((response) => {
        axios
          .get(`https://topups.reloadly.com/countries`, {
            headers: {
              Authorization: `Bearer ${response.data.token}`,
            },
          })
          .then((response) => {
            console.log(response.data);
            setCountries(response.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    dispatch(getLocation());
  }, [dispatch]);

  useEffect(() => {
    if (countries) {
      const foundCountry = countries.find((country) => {
        return country.currencyCode === user.currency;
      });
      if (foundCountry) {
        console.log(foundCountry);
        setCurrency(foundCountry);
      }
    }
  }, [countries, user.currency]);
  return (
    <div>
      {!clientSecret && (
        <div className="amount__form">
          <div className="form__group">
            <input
              type="number"
              name="amount"
              placeholder="Amount"
              className="form__control"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <button onClick={generateIntent}>
            {loading ? (
              <ReactLoading color="#fff" width={30} height={30} />
            ) : (
              <>
                Pay {currency && currency.currencySymbol}
                {amount}
              </>
            )}
          </button>
        </div>
      )}
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentForm clientSecret={clientSecret} amount={amount} />
        </Elements>
      )}
      <ToastContainer />
    </div>
  );
};

export default StripePay;
