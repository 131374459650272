export const getLocation = () => {
  return (dispatch) => {
    fetch("https://api.geoapify.com/v1/ipinfo?apiKey=e25815bfbee444169129113cac9462aa", { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.country)
        dispatch({
          type: "GET_LOCATION",
          payload: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
